import * as React from "react";
import Section from "@components/Section";
import { useColorMode } from "theme-ui";

import { Box, Text } from "rebass";
import { TypedAnimation } from "@digitaltransformation/qone.pages.layout";
import themeProps from "../gatsby-plugin-theme-ui/index";
import styled from "styled-components";

export interface ICoverContent {
    fill: string;
    subtitle?: string | React.ReactNode | React.ReactElement;
    title: string[];
    text?: string | React.ReactNode | React.ReactElement;
}

class CoverContent extends React.Component<ICoverContent> {
    public render() {
        return (
            <>
                <Section relative>
                    <Box
                        py={[6, 12]}
                    >
                        <Text
                            style={{
                                padding: "1em 0",
                                fontWeight: 900,
                                fontSize: "1em",
                                color: this.props.fill,
                                textTransform: "uppercase",
                            }}
                        >
                            {this.props.subtitle}
                        </Text>
                        {this.props.title.length == 1 ? (
                            <Title
                                style={{
                                    color: this.props.fill,
                                    fontFamily: themeProps.fonts.serif,
                                }}
                            >
                                {this.props.title[0]}
                            </Title>
                        ) : (
                            <TypedAnimation
                                content={this.props.title}
                                style={{
                                    padding: "1em 0",
                                    color: this.props.fill,
                                    fontFamily: themeProps.fonts.serif,
                                    fontSize: "3em",
                                    fontWeight: 100,
                                }}
                            />
                        )}
                        <Text
                            style={{
                                padding: "1em 0",
                                maxWidth: "25em",
                                color: this.props.fill,
                                fontSize: "1.25em",
                                fontWeight: 100,
                                lineHeight: "1.25",
                            }}
                        >
                            {this.props.text}
                        </Text>
                    </Box>
                </Section>
            </>
        );
    }
}

const StateWrapper = (component) => (props) => {
    const colorMode = useColorMode();
    const isDark = colorMode[0] === "dark";
    const fill = isDark ? "#fff" : "#000";

    return <CoverContent {...props} fill={themeProps.colors.white} />;
};

export default StateWrapper(CoverContent);

const Title = styled(Text)`
    font-size: 3em;
    font-weight: 100;
`;
