import * as React from "react";
import { useColorMode } from "theme-ui";
import { graphql, StaticQuery } from "gatsby";
import { Text } from "rebass";

import NavigationHeader from "@components/Navigation/Navigation.Header";

import CoverContainer from "../../../../components/containers/CoverContainer";
import CoverContent, { ICoverContent } from "../../../../content/CoverContent";

const content = {
    media: {
        url: "https://s3.ca-central-1.amazonaws.com/static-nuvahs-edge-node-ca-central.repo.host/video-cdn/pexels-cottonbro-6467633_1080_compressed_x264_28.mp4",
        placeholder: "https://s3.ca-central-1.amazonaws.com/static-nuvahs-edge-node-ca-central.repo.host/video-cdn/pexels-cottonbro-6467633_1080.jpg",
    },
};

interface IMainCoverImplProps {
    fill?: string;
    cover?: {
        url: string;
        image: string;
    };
    renderContent?: boolean;
    content?: ICoverContent;
}

class MainCoverImpl extends React.Component<IMainCoverImplProps> {
    public render() {
        return (
            <CoverContainer
                url={this.props.cover?.url ?? content.media.url}
                image={this.props.cover?.image ?? content.media.placeholder}
            >
                <NavigationHeader />
                {this.props.renderContent ?? (
                    <>
                        <CoverContent
                            fill={this.props.fill}
                            subtitle={this.props.content.subtitle}
                            title={this.props.content.title}
                            text={
                                <>
                                    <Text>{this.props.content.text}</Text>
                                    {this.props.children}
                                </>
                            }
                        />
                    </>
                )}
            </CoverContainer>
        );
    }
}

const StateWrapper = (component) => (props) => {
    const colorMode = useColorMode();
    const isDark = colorMode[0] === "dark";
    const fill = isDark ? "#fff" : "#000";

    return <MainCoverImpl {...props} fill={fill} />;
};

export default StateWrapper(MainCoverImpl);
