import * as React from "react";
import { useColorMode } from "theme-ui";
import { IAngleProps, MainCover } from "@digitaltransformation/qone.pages.layout";
import { Box } from "rebass";

interface ICoverContainerProps {
    url?: string,
    image?: HTMLImageElement | string;
    angle?: IAngleProps,
    style?: {
        container?: React.CSSProperties,
        media?: React.CSSProperties
    };
    fill: string;
    children?: any;
}

/**
 * Implementation of MainCover media container
 * Renders pass-through children components
 */
class CoverContainer extends React.Component<ICoverContainerProps> {
    public render() {
        const darkModeFill = this.props.fill == "#000" ? "#fff" : "#000";

        return (
            <MainCover
                media={{
                    url: this.props.url ?? undefined,
                    placeholder: this.props.image,
                    styles: {
                        filter: 'brightness(0.66) saturate(1.33)',
                        backgroundColor: darkModeFill,
                        transform: "scale(1)",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "right center",
                        ...this.props.style?.media,
                    },
                }}
                styles={{
                    ...this.props.style?.container,
                }}
                angle={{
                    ...this.props.angle
                }}
            >
                {this.props.children}
                <Box style={{ padding: "3em 0" }} />
            </MainCover>
        );
    }
}

const StateWrapper = (component) => (props) => {
    const colorMode = useColorMode();
    const isDark = colorMode[0] === "dark";
    const fill = isDark ? "#fff" : "#000";

    return <CoverContainer {...props} fill={fill} />;
};

export default StateWrapper(CoverContainer);
